<app-header-pre-login></app-header-pre-login>
<div class="LoginOuter JMF_LoginBg" >
    <div class="MainOuter" [ngClass]="!IshowLoginPage ? 'loader_nsdl':''">
        <div class="card mt-3 tab-card" *ngIf="IshowLoginPage">
            <div class="UnderLineTabs">
                <ul class="nav nav-tabs" id="myTab" role="tablist" [ngClass]="CompanyName== 'NSDL'? 'twofr' : 'threefr'">
                    <li class="nav-item">
                        <a class="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab"
                            aria-controls="One" aria-selected="true" (click)="switchTab('One')">Mobile</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link " id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="Two"
                            aria-selected="false" (click)="switchTab('Two')">Email</a>
                    </li>
                    <li class="nav-item" [ngClass]="CompanyName== 'NSDL'? 'd-none': 'd-block'">
                        <a class="nav-link " id="three-tab" data-toggle="tab" href="#three" role="tab"
                            aria-controls="Three" aria-selected="false" (click)="switchTab('Three')">PAN</a>
                    </li>
                </ul>
            </div>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade px-4 show active" id="one" role="tabpanel" aria-labelledby="one-tab" [ngClass]="IsMobile ? 'pt-4':'pt-5'">
                    <div class="row">
                        <div class="col-md-12 mx-auto">
                            <div class="step-opne">
                                <form autocomplete="off" [formGroup]="MobileLoginForm">
                                    <div class="row">
                                        <div class="col TbxMarginBtm">
                                            <div class="has-float-label">
                                                <input id="Mobile" type="text" formControlName="Mobile"
                                                    class="form-control CommonTextBox numonly"
                                                    [ngClass]="{ 'is-invalid': isMobileLoginFormSubbmitted && j.Mobile.errors }"
                                                    placeholder="&nbsp;" maxlength="10" (change)="SendOtpToMobile()"
                                                     autocomplete="off" (input)="validateNumbers($event)"/><!--(keypress)="isNumber($event)"-->
                                                <label class="lable" for="Mobile">Mobile Number<span
                                                        class="ColorRed">*</span></label>
                                                <div *ngIf="isMobileLoginFormSubbmitted && j.Mobile.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="j.Mobile.errors.required">Please enter mobile number
                                                    </div>
                                                    <div *ngIf="j.Mobile.errors.pattern">Enter Valid mobile number.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col" [ngClass]="!IsMobile ? 'TbxMarginBtm':''">
                                            <div class="has-float-label">

                                                <!-- <show-hide-password btnStyle="" [btnOutline]="false"> -->
                                                <input id="MobileOTP" autocomplete="off" type="password" formControlName="MobileOTP"
                                                    (keypress)="isNumber($event)" class="form-control CommonTextBox numonly"
                                                    placeholder="&nbsp;" maxlength="6" 
                                                    [ngClass]="{ 'is-invalid': isMobileLoginFormSubbmitted && j.MobileOTP.errors }" />
                                                <label class="lable" for="MobileOTP">Enter OTP<span class="ColorRed">
                                                        *</span></label>
                                                <div *ngIf="isMobileLoginFormSubbmitted && j.MobileOTP.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="j.MobileOTP.errors.required">Please enter OTP</div>
                                                    <div *ngIf="j.MobileOTP.errors.pattern">Enter 6 Digit OTP Number
                                                    </div>
                                                </div>
                                                <!-- </show-hide-password> -->
                                            </div>
                                            <div routerLinkActive="active" class="ForgetPassLink"
                                                (click)="ResendOtpToMobile()">
                                                Resend OTP
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="CompanyName == 'NSDL'">
                                        <div class="col InputBoxOuter">
                                            <!-- <div class="row"> -->
                                            <div class="CaptchaDiv d-flex">
                                                <div class="captcha">
                                                    <canvas id="captcahCanvasMobile" width="325" height="80" class="captchatext"></canvas>
                                                </div>
                                                <div class="my-auto mx-3">
                                                    <span (click)="generateCaptcha()"><i class="fa fa-refresh" aria-hidden="true"
                                                            style="font-size: 36px;"></i></span>
                                                </div>
                                            </div>
                                            <!-- </div> -->
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="CompanyName == 'NSDL'">
                                        <div class="col InputBoxOuter">
                                    
                                            <input type="text" formControlName="Captcha" class="form-control CommonTextBox"
                                                placeholder="Enter Captcha" maxlength="6" [ngClass]="{ 'is-invalid': LoginSubmitted && r.Captcha.errors }"
                                                (keypress)="ValidatePattern('NumberandCharc',$event)" autocomplete="off" onpaste="return false" />
                                            <div *ngIf="LoginSubmitted && r.Captcha.errors" class="invalid-feedback">
                                                <div *ngIf="r.Captcha.errors.required">Please enter captcha.</div>
                                            </div>
                                            <div class="invalid-feedback" [ngClass]='InvalidCaptcha?"d-block": "d-none"'>
                                                Please enter valid captcha.</div>
                                    
                                        </div>
                                    
                                    </div>

                                    <div class="text-center SubmitMarginTop" [ngClass]="IsMobile ? 'mb-3':''">
                                        <button class="CommonSubmitButton"
                                            [disabled]='j.Mobile.value == "" || j.MobileOTP.value == ""'
                                            (click)="OnMobileLogin()" [ngClass]="IsMobile ? 'w-100':''">Login</button>
                                    </div>
                                    <div *ngIf='CompanyName!="ABML_Money"' class="col-12">
                                        <div class="CreateAccount">
                                            <div class="w-100 text-center">

                                                If you are a new client of {{WelcomeText}},
                                                <span class="BlueText" routerLink="/client-preOnboading"
                                                    routerLinkActive="active">click here to create a new
                                                    account.</span><br />

                                                If you are an existing {{WelcomeText}} client and don't have a
                                                password
                                                <span class="BlueText" routerLink="/generate-password"        
                                                    routerLinkActive="active">Click Here</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf='CompanyName=="ABML_Money"' class="col-12">
                                        <div class="CreateAccount">
                                            <div class="w-100 text-center">
                                                If you are a new client of {{WelcomeText}},
                                                <span class="BlueText" (click)="Gotolink()">click here to create a new
                                                        account.</span><br />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                             
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade px-4 " id="two" role="tabpanel" aria-labelledby="two-tab" [ngClass]="IsMobile ? 'pt-4':'pt-5'">
                    <div class="row">
                        <div class="col-md-12 mx-auto">
                            <div class="step-opne">
                                <form autocomplete="off" [formGroup]="EmailLoginForm">
                                    <div class="row">
                                        <div class="col TbxMarginBtm">
                                            <div class="has-float-label">
                                                <input id="Email" type="text" formControlName="Email" autofocus
                                                    class="form-control CommonTextBox"
                                                    [ngClass]="{ 'is-invalid': isEmailFormSubbmitted && e.Email.errors }"
                                                    placeholder="&nbsp;" (change)="SendOtpToEmail()"  autocomplete="off"/>
                                                <label class="lable" for="Email">Enter Email<span class="ColorRed">
                                                        *</span></label>
                                                <div *ngIf="isEmailFormSubbmitted && e.Email.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="e.Email.errors.required">Please enter Email</div>
                                                    <div *ngIf="e.Email.errors.pattern">Enter valid email ID</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col" [ngClass]="!IsMobile ? 'TbxMarginBtm':''">
                                            <div class="has-float-label">
                                                <!-- <show-hide-password btnStyle="" [btnOutline]="false"> -->
                                                <input id="EmailOTP" autocomplete="off" type="password" formControlName="EmailOTP"
                                                    (keypress)="isNumber($event)" class="form-control CommonTextBox numonly"
                                                    placeholder="Enter OTP" maxlength="6" placeholder="&nbsp;"
                                                    [ngClass]="{ 'is-invalid': isEmailFormSubbmitted && e.EmailOTP.errors }" />
                                                <label class="lable" for="EmailOTP">Enter OTP<span class="ColorRed">
                                                        *</span></label>
                                                <div *ngIf="isEmailFormSubbmitted && e.EmailOTP.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="e.EmailOTP.errors.required">please enter otp</div>
                                                    <div *ngIf="e.EmailOTP.errors.pattern">Enter 6 digit OTP number
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- </show-hide-password> -->
                                            <div routerLinkActive="active" class="ForgetPassLink"
                                                (click)="onClickReSendEmailOTP()">
                                                Resend OTP
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="CompanyName == 'NSDL'">
                                        <div class="col InputBoxOuter">
                                            <!-- <div class="row"> -->
                                            <div class="CaptchaDiv d-flex">
                                                <div class="captcha">
                                                    <canvas id="captcahCanvasEmail" width="325" height="80" class="captchatext"></canvas>
                                                </div>
                                                <div class="my-auto mx-3">
                                                    <span (click)="generateCaptcha()"><i class="fa fa-refresh" aria-hidden="true"
                                                            style="font-size: 36px;"></i></span>
                                                </div>
                                            </div>
                                            <!-- </div> -->
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="CompanyName == 'NSDL'">
                                        <div class="col InputBoxOuter">
                                    
                                            <input type="text" formControlName="Captcha" class="form-control CommonTextBox"
                                                placeholder="Enter Captcha" maxlength="6" [ngClass]="{ 'is-invalid': LoginSubmitted && r.Captcha.errors }"
                                                (keypress)="ValidatePattern('NumberandCharc',$event)" autocomplete="off" onpaste="return false" />
                                            <div *ngIf="LoginSubmitted && r.Captcha.errors" class="invalid-feedback">
                                                <div *ngIf="r.Captcha.errors.required">Please enter captcha.</div>
                                            </div>
                                            <div class="invalid-feedback" [ngClass]='InvalidCaptcha?"d-block": "d-none"'>
                                                Please enter valid captcha.</div>
                                    
                                        </div>
                                    
                                    </div>

                                    <div class="text-center SubmitMarginTop" [ngClass]="IsMobile ? 'mb-3':''">
                                        <button class="CommonSubmitButton"
                                            [disabled]='e.Email.value == "" || e.EmailOTP.value == ""'
                                            (click)="onEmailLoginClick()" [ngClass]="IsMobile ? 'w-100':''">Login</button>
                                    </div>
                                    <div *ngIf='CompanyName!="ABML_Money"' class="col-12">
                                        <div class="CreateAccount">
                                            <div class="w-100 text-center">

                                                If you are a new client of {{WelcomeText}},
                                                <span class="BlueText" routerLink="/client-preOnboading"
                                                    routerLinkActive="active">click here to create a new
                                                    account.</span><br />

                                                If you are an existing {{WelcomeText}} client and don't have a
                                                password
                                                <span class="BlueText" routerLink="/generate-password"        
                                                    routerLinkActive="active">Click Here</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf='CompanyName=="ABML_Money"' class="col-12">
                                        <div class="CreateAccount">
                                            <div class="w-100 text-center">
                                                If you are a new client of {{WelcomeText}},
                                                <span class="BlueText" (click)="Gotolink()">click here to create a new
                                                        account.</span><br />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade px-4  " id="three" role="tabpanel" aria-labelledby="two-tab" [ngClass]="IsMobile ? 'pt-4':'pt-5'">
                    <div class="row">
                        <div class="col-md-12 mx-auto">
                            <div class="step-opne">
                                <form autocomplete="off" [formGroup]="LoginForm" (ngSubmit)="onSubmit()">
                                    <div class="row">
                                        <div class="col TbxMarginBtm">
                                            <div class="has-float-label">
                                                <input id="Username" type="text" formControlName="Username"
                                                    oninput="this.value = this.value.toUpperCase()" autofocus
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': LoginSubmitted && r.Username.errors }"
                                                    placeholder="&nbsp;" maxlength="10"
                                                    class="form-control CommonTextBox" autocomplete="off" />
                                                <label class="lable" for="Username">PAN Number<span class="ColorRed">
                                                        *</span></label>
                                                <div *ngIf="LoginSubmitted && r.Username.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="r.Username.errors.required">Please enter your pan
                                                        number.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col" [ngClass]="!IsMobile ? 'TbxMarginBtm':''">
                                            <div class="has-float-label">

                                                <show-hide-password btnStyle="" [btnOutline]="false">
                                                    <input id="Password" autocomplete="off" type="password" formControlName="Password"
                                                        placeholder="&nbsp;"
                                                        class="form-control CommonTextBox passwordtxt" maxlength="20" 
                                                        [ngClass]="{'is-invalid':LoginSubmitted && r.Password.errors}"/>
                                                    <label class="lable" for="Password">Enter Password<span
                                                            class="ColorRed">
                                                            *</span></label>
                                                    <div *ngIf="LoginSubmitted && r.Password.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="r.Password.errors.required">Please enter your
                                                            password.
                                                        </div>
                                                        <div *ngIf="r.Password.errors.pattern" >Password contains atleast
                                                            1
                                                            numeric , 1 uppercase, 1 lowercase and 1 special character
                                                        </div>
                                                        <!--Password contains atleast 1 number, 1 uppercase, 1 lowercase and 1 special charactor (@$!%*?&#)-->
                                                    </div>
                                                </show-hide-password>
                                            </div>

                                            <div routerLink="/generate-password" routerLinkActive="active"
                                                class="ForgetPassLink" (click)="SendUserType()">
                                                Forgot Password?
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="CompanyName == 'NSDL'">
                                        <div class="col InputBoxOuter">
                                            <!-- <div class="row"> -->
                                            <div class="CaptchaDiv d-flex">
                                                <div class="captcha">
                                                    <canvas id="captcahCanvasPAN" width="325" height="80" class="captchatext"></canvas>
                                                </div>
                                                <div class="my-auto mx-3">
                                                    <span (click)="generateCaptcha()"><i class="fa fa-refresh" aria-hidden="true"
                                                            style="font-size: 36px;"></i></span>
                                                </div>
                                            </div>
                                            <!-- </div> -->
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="CompanyName == 'NSDL'">
                                        <div class="col InputBoxOuter">
                                    
                                            <input type="text" formControlName="Captcha" class="form-control CommonTextBox"
                                                placeholder="Enter Captcha" maxlength="6" [ngClass]="{ 'is-invalid': LoginSubmitted && r.Captcha.errors }"
                                                (keypress)="ValidatePattern('NumberandCharc',$event)" autocomplete="off" onpaste="return false" />
                                            <div *ngIf="LoginSubmitted && r.Captcha.errors" class="invalid-feedback">
                                                <div *ngIf="r.Captcha.errors.required">Please enter captcha.</div>
                                            </div>
                                            <div class="invalid-feedback" [ngClass]='InvalidCaptcha?"d-block": "d-none"'>
                                                Please enter valid captcha.</div>
                                    
                                        </div>
                                    
                                    </div>

                                    <div class="text-center SubmitMarginTop" [ngClass]="IsMobile ? 'mb-3':''">
                                        <button class="CommonSubmitButton" *ngIf="this.CompanyName == 'NSDL'"
                                            [disabled]='r.Username.value == "" || r.Password.value == "" || r.Captcha.value == ""' [ngClass]="IsMobile ? 'w-100':''">Login</button>
                                        <button class="CommonSubmitButton" *ngIf="this.CompanyName != 'NSDL'"
                                            [disabled]='r.Username.value == "" || r.Password.value == ""' [ngClass]="IsMobile ? 'w-100':''">Login</button>
                                    </div>
                                    <div *ngIf='CompanyName!="ABML_Money"' class="col-12">
                                        <div class="CreateAccount">
                                            <div class="w-100 text-center">

                                                If you are a new client of {{WelcomeText}},
                                                <span class="BlueText" routerLink="/client-preOnboading"
                                                    routerLinkActive="active">click here to create a new
                                                    account.</span><br />

                                                If you are an existing {{WelcomeText}} client and don't have a
                                                password
                                                <span class="BlueText" routerLink="/generate-password"        
                                                    routerLinkActive="active">Click Here</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf='CompanyName=="ABML_Money"' class="col-12">
                                        <div class="CreateAccount">
                                            <div class="w-100 text-center">
                                                If you are a new client of {{WelcomeText}},
                                                <span class="BlueText" (click)="Gotolink()">click here to create a new
                                                        account.</span><br />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!IshowLoginPage && CompanyName == 'NSDL'">
            <!-- <h5>Please wait while fetching data...</h5> -->
            <img src="../../assets/images/Loader_NSDL.gif" alt="" width="100px" height="100px">
        </div>
    </div>
</div>
<!-- The Modal End -->
<div class="modal" id="AlertModalLogin" appDisabledRightClick>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content AlertModalContentCls">
        <div class="modal-header AlertModalHeader">Select Client</div>
        <div id="AlertModalContentv" class="modal-body AlertModalBody">

            <div class="tab-content TabContentData px-0">
                <div id="Clients" class="tab-pane fade show active">
                    <div class="TableDataMainData MainDataLRPadding pt-15">
                        <div class="table-responsive">
                            <table id="ClientsTable" class="CommonTable ClientsTable"
                                *ngIf="isMobileListDisplay">
                                <thead>
                                    <tr>
                                        <th>Name.</th>
                                        <th>PAN Number</th>
                                        <th>Mobile Number</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let client of ClientData">
                                        <td>{{client.FirstName}}</td>
                                        <td>{{client.PANNo}}</td>
                                        <td>{{client.MobileNo}}</td>
                                        <td>
                                            <button class="BtnCommon" style="background-color: var(--primary-color);"
                                                (click)="OnClickClientSelection(client)">Login</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer AlertModalFooter">
           
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="AlertModalLoginEmail" appDisabledRightClick>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content AlertModalContentCls">
        <div class="modal-header AlertModalHeader">Select Client</div>
        <div id="AlertModalContentv5" class="modal-body AlertModalBody">

            <div class="tab-content TabContentData px-0">
                <div id="Clients" class="tab-pane fade show active">
                    <div class="TableDataMainData MainDataLRPadding pt-15">
                        <div class="table-responsive">
                            <table id="ClientsTable" class="CommonTable ClientsTable"
                                *ngIf="isEmailListDisplay">
                                <thead>
                                    <tr>
                                        <th>Name.</th>
                                        <!-- <th>PAN Number</th> -->
                                        <th>Email Id</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let client of ClientDataBasedOnEmail">
                                        <td>{{client.FirstName}}</td>
                                        <!-- <td>{{client.PANN}}</td> -->
                                        <td>{{client.EmailId}}</td>
                                        <td>
                                            <button class="BtnCommon" style="background-color: var(--primary-color);"
                                                (click)="BasedonEmailSelection(client)">Login</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer AlertModalFooter">
           
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="AlertModal_NSDL" appDisabledRightClick>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content AlertModalContentCls">
        <div class="modal-header AlertModalHeader">Important Message</div>
        <div class="modal-body AlertModalBody">
            Mutual Fund investment made easy. Invest today and watch your wealth grow.
        </div>
        <div class="modal-footer AlertModalFooter">
          <button type="button" data-dismiss="modal" class="CursorPointer" (click)="RedirectToOnboarding()">Okay</button>
        </div>
      </div>
    </div>
  </div>


