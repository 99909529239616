export const environment = {
  production: true,
  CommonBaseHref:'',
  CompayName: "NSDL",
  CompanyId:1,
  environmentDigio:"sandbox",
  //ServerUrl: 'http://192.168.95.157:9094/',
  // ServerUrl: 'https://newmfapi.adityabirlamoney.com/' // ABML URL
  // ServerUrl: 'https://newmfapi.adityabirlamoney.com/'
  // ServerUrl: 'http://191.191.11.130:7072/'    // Alankit URL
  ServerUrl: 'https://mfready.nsdlbank.com/wrapperapi/'//NSDL Live
};



